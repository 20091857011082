// Variables
@import "variables";
@import "partials/_vars";
@import url('https://fonts.googleapis.com/css?family=Heebo');

// VueTables
@import "vuetables";

// Email editors
@import "email-editors";

body {
    font-family: 'Heebo', sans-serif;
    padding: 0!important;
}
@media only screen and (max-width: 500px) {
    .g-recaptcha-bubble-arrow {
        display: none;
    }
}

#app, .center-form-box {
    min-height: calc(100vh - 344px);

}

.half-width-box {
    width: 49%;
    border: 6px solid $colorGrey5;
    float: right;
    padding: 1% 2%;
    // padding-right: 5%;
    border-radius: 3px;
    &:first-child {
        margin-left: 2%;
    }
    .login-form {
        label {
            width: 35%;
        }
    }
    input {
        width: 50% !important;
    }
    &.center {
        margin: 0 auto;
        float: none;
    }
}

.center-form-box {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    float: none;
    padding: 2%;
    background-color: #f9f9f9;
    border-radius: 3px;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 60px;
    margin-bottom: 60px;

    h3 {
        color: $colorGrey7;
    }

    hr {
        border: 1px solid $colorGrey5;
    }
}

.container-half-width-box {
    margin: 50px;
    position: relative;
}

.half-width-box-or {
    color: $colorGrey2;
    background-color: $colorGrey5;
    color: #a1a0a5;
    background-color: #e8e8e8;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 76px;
    font-size: 24px;
    font-weight: bold;
    box-shadow: 0px 11px 0px 24px #f9f9f9;
    margin: 0 auto;
}

.reveal-overlay-vue {
    display: block !important;
    background-color: $colorGrey5;
}

div.reveal {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 50px !important;
    top: 50px;
}

.reveal-view-order {
    width: 100%;
    min-width: 800px;
    max-width: 1170px;
    border: 0;
    border-radius: 4px;
}

.row-action {
    cursor: pointer;
    &:hover {
        background-color: $colorHoverMarker;
        .fa.fa-eye {
            opacity: 1;
        }
    }
    .fa.fa-eye {
        opacity: 0.2;
    }
    &.active {
        background-color: $colorActiveMarker;
        .fa.fa-eye {
            opacity: 1;
        }
    }
}

.markpink {
    &:hover {
        background-color: $colorHoverMarker;
    }
}

.tblmarkpink>tbody>tr:hover {
    background-color: $colorHoverMarker;
}

.eye-wrapper {
    text-align: center !important;
}

.view-order-summarize {
    margin:0 50px 0 50px;
    text-align: left;
    float: left;
}

.btnleftcorner {
    position: absolute;
    left: 7px;
    top: 7px;
}

.btn-left-corner-second {
    position: absolute;
    left: 95px;
    top: 7px;
}

.breadcrumbs {
    li {
        font-size: 1em;
    }
}

a.button.active {
    background-color: $colorGreen !important;
    color: #fefefe !important;
}

.btn-in-td {
    padding: 5px;
    margin-top: -12px;
    margin-bottom: -10px;
}

.pointer {
    cursor: pointer;
}

.amb-btn, .amb-btn:hover {
    border-radius: 5px;
    background-color: #ec5840 !important;
}

.mb50 {
    margin-bottom: 50px;
}

.close-button {
    color: rgba(255,255,255,0.75);
    font-weight: bold;
    &:hover, &:focus {
        color: rgba(255,255,255,1);
    }
}

// .sf-menu .fa-shopping-cart {
//     font-size: 26px;
// }

// .sf-menu .link-cart {
//     padding: 0;
// }

// .sf-menu .link-cart:hover {
//     background: none;
// }

.sf-menu .link-spec {
    background-color: #c66c66;
    border-radius: 7px;
}

// .bt-label {
//     display: inline;
//     padding: .2em .6em .3em;
//     font-size: 75%;
//     font-weight: 300;
//     line-height: 1;
//     color: $colorWhite;
//     text-align: center;
//     white-space: nowrap;
//     vertical-align: baseline;
//     border-radius: 2px;
// }

.green-label {
    background: $colorGreen5;
}

.red-label {
    background: $colorRed3;
}

.canceled-label {
    background: #8e8686;
}

.orange-label {
    background: $colorOrange;
}


#order-boxes {
    margin: 0;
    padding: 0 0 40px 0;
}

.header-lined-order h1 {font-size:22px; font-weight:700; color: $colorWhite; margin:0 0 20px; padding:15px 0; background: $colorGrey2; letter-spacing: -.07; text-transform: uppercase; position: relative; text-align: center; border:0!important;}
.header-lined-order h1:after { top: 100%; left: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: $colorWhite; border-top-color: $colorGrey2; border-width: 10px; margin-left: -10px; }

.spacing-5 {padding-top:5px;}

#order-boxes .header-lined, #order-boxes .header-lined h2 { margin:0;}

#order-boxes .line-padded {
    margin-top: 15px;
    margin-bottom: 15px;
}

#order-boxes .form-inline-always .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

#order-boxes .fields-container {
    border: 0;
}

#order-boxes .fields-container .field-row {
    padding: 10px 8px;
    margin-bottom:8px;
     width:100%;
}

#order-boxes .fields-container .field-row:nth-child(odd) {
    background-color: #F0F1F5;
    color: #5a5a5a;
    border-radius:5px;
}

#order-boxes .fields-container .field-row:nth-child(even) {
    background-color: #F0F1F5;
    color: #5a5a5a;
    border-radius:5px;
}

#order-boxes .product-radio {
    padding-left: 40px;
}
#order-boxes select, #order-boxes input[type="text"], input[type="number"] {
    height:37px;
    font-size:15px;
    line-height:37px;
}
#order-boxes .product-radio input[type=radio] {
    margin-left: -30px;
}


#order-boxes .large-text {
    font-size: 1.4em;
}

#order-boxes table.styled {
    width: 100%;
}

#order-boxes table.styled tr th {
    background-color: #6E6E6E;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 5px;
}

#order-boxes table.styled tr td {
    padding: 5px;
    border-bottom: 1px solid #ccc;
    font-size: 0.9em;
}

#order-boxes table.styled tr td.leftcol {
    padding: 5px 15px;
    width: 30%;
}

#order-boxes table.styled tr.carttableconfig td {
    background-color: #efefef;
    font-size: 0.8em;
    padding: 2px;
}

#order-boxes table.styled tr.carttablesummary td {
    background-color: #E8F0F9;
    color: #222222;
    height: 20px;
    font-size: 1.0em;
    font-weight: bold;
}

#order-boxes table.styled tr.carttablesummary td.left {
    padding: 5px 15px;
    text-align: right;
}

#order-boxes .textgreen {
    color: #779500;
}

#order-boxes .textred {
    color: #cc0000;
}

#order-boxes .secure-warning {
    margin: 20px 0 20px 0;
    padding: 7px;
    border-top: 4px solid #EBE8CD;
    border-bottom: 4px solid #EBE8CD;
    background-color: #F1EFDC;
    font-size: 0.9em;
    text-align: center;
    color: #000;
}
#order-boxes button[type="submit"] {width:auto; height:auto; font-size:15px; float:none!important; margin:0 auto!important; display: table!important; text-align: center}


@media only screen and (max-width: 659px) {
a.btn {width:100%; margin-bottom:5px;}
#order-boxes button[type="submit"] {font-size:15px; width:100%; margin-bottom:5px;}
}

.button {
    background-color: $colorBtn;
    color: $colorWhite;
    border-radius: 4px;
}

.button:hover, .button:focus {
    background-color: $colorBtnHover;
    color: $colorWhite;
}

.submenu {
    border-radius: 0;
}

h3.header-lined {
    margin-top: 0;
    margin-bottom: 25px;
    padding: 6px 0;
    border-bottom: 1px solid $colorBlue2;
    font-weight: 400;
    color: $colorBlue2;
    text-align: center;
}

.submit-form-wrapper {
    margin-top: 30px;
    text-align: center;
}

.vue-slider-wrap .vue-slider {
    background-color: #b7cddc !important;
}

.error-label {
    color: $colorRed1;
    text-align: left;
}

input {
    &.input-field {
        display: inline-block;
        padding: 0 .4em 0 .4em !important;
        margin-bottom: 2em !important;
        vertical-align: middle;
        border-radius: 3px;
        min-width: 50px;
        max-width: 635px;
        width: 100% !important;
        min-height: 32px;
        background-color: #ffffff !important;
        border: 2px solid #c9c9c9 !important;
        margin: 0 0 0 0 !important;
        min-height: 52px;
        font-size: 17px;
        line-height: 20px;
        padding: 0 15px;

        &:hover {
            border-color: $colorGrey7 !important;
        }

        &:focus {
            border-color: $colorPurple !important;
        }
    }
}

.checkbox-big {
    background: #fff;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 20px;
    margin: -4px 4px 0px 0px;
    outline: 0;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    width: 20px;
    -webkit-appearance: none;
    font-size: 14px;
    border:2px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #c9c9c9;
    -moz-box-shadow: 0px 0px 0px 2px #c9c9c9;
    box-shadow: 0px 0px 0px 2px #c9c9c9;

    &:hover {
        border-color: $colorGrey7 !important;
    }

    &:checked {
        background: $colorPurple;
    }
}

.reveal-terms {
    width: 800px;
    height: 500px;
    border-radius: 3px;
    cursor: s-resize;
}

@media only screen and (min-width : 1224px) {

    .flex-login-container {
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        max-width: 1400px;
        margin: 0 auto;

        .half-width-box-or {
            margin-top: 10%;
        }
    }
}
.reveal-count,
.reveal-download {
    width:500px;
    min-width:500px;
    border: 1px solid $colorGrey7;
    border-radius: 25px;
}

.header-lined-order-count,
.header-lined-order-download {
    font-size: 20px;
    text-align: center;
    line-height: 52px;
    .close-button {
        color: $colorGrey7;
    }
}

.btn-download-pdf {
    text-decoration: underline;
    text-decoration-color: $colorGrey7;
}

.footer-short {
    padding: 15px 0 15px;
    background-color: $colorGrey5;
    p.copyright {
        color: #222D3A;
        padding: 0;
    }
}
.counting-invoice-wrapper-st,
.downloading-invoice-wrapper-st {
    font-size: 17px;
}

.pop-action {
    color: $colorGrey8;
    line-height: 2;
}

.modal-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 2px;
    width: 7px;
}
.modal-scrollbar::-webkit-scrollbar {
    width: 7px;
    background-color: #fff;
    border-radius: 2px;
}
.modal-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $colorBlue3;
    width: 7px;
}

.modal-container {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    padding: 0 40px;
}


.information-modal{
    margin-top: 250px;
    z-index: 100000;

    .modal-dialog-style{
        width: 600px;
    }
    .modal-header-style{
        padding: 15px;
    }

    .btn-style{
        background-color: #3085d6;
        border-radius: 3px;
        box-shadow: none;
        color: #fff;
        cursor: pointer;
        font-size: 17px;
        margin: 10px 6px;
        padding: 9px 31px;
    }
    .btn-style:hover{
        background-color: rgb(43, 120, 193);
        color: #fff ;
    }
    #info-modal-content{
        box-shadow: none;
        border: none;
    }
    div.tab {
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #f1f1f1;
    }

    .nav>li>a:hover{
        background-color: #82868b;
        color: #fff;
    }

    div.tab button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
    }

    div.tab button:hover {
        background-color: #82868b;
    }

    .tabcontent {
        padding: 6px 12px;
    }
    .modal-open .modal{
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .text-style-info
    {
        text-align: justify;
        line-height: 23px;
        font-size: 15px;
        padding-top: 10px;
    }
    .info-title{
        padding-top: 5px;
        font-size: 25px;
    }
    .content-div{
        width: 447px;
        padding-right: 30px;
    }

    #info-modal-content{
        overflow-y: auto;
    }

    .modal-open #informModal{
        overflow-y: hidden;
    }

    .tabs-li{
        font-size: 15px;
        margin: 57px 0px;
    }
    li.active {
        background-color: rgba(194, 199, 205, 0.88);
        color: #fff;
    }
    .buttonsStyle .btn{
        border-radius: 3px;
    }
}

.modal1 {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

#bigDiv{
  width: 100%;
  background-color: #8b5657;
  position: fixed;
  bottom: 0;
  z-index: 10000000000;
  padding-top: 5px;

    .textStyle{
        width: 76%;
        float: left;
        padding: 2px;
        color: #ffffff;
    }

    .buttonsStyle{
        padding: 18px;
        padding-right: 5%;
    }
  .cookie-note {
    text-align: center;
    white-space: nowrap;
  }
}
.center-form-box-doa,
.center-form-box-cookie-policy{
    width: 100%;
    max-width: 800px;
    min-width: 300px;
    float: none;
    padding: 2%;
    background-color: #f9f9f9;
    border-radius: 3px;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-bottom: 60px;
}
.flex-login-container-doa,
.flex-login-container-cookie-policy{
    display: -webkit-box;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 auto;
    width: 60%;
}
.left {
  white-space: nowrap;
  text-align: center;
}
.closeButton{
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fdfffc;
    text-shadow: 0 1px 0 #a6a9a8;
    padding-left: 2px;
}
.span-info-style{
    color: #fdfffc;
    font-weight: bold;
}
.close-button-style{
  height: 25px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}
.we-use-info-style{
    color: #fdfffc;
}
.big-div-row-style{
    padding-top: 3px;
}
.learn-more{
    color: rgb(253, 255, 252);
}

.forever-close-text{
    margin-top: -1px;
    white-space: nowrap;
}

.checkbox-style{
    display: inline;
}

//Cookie Policy page toggle start
.switch {
    position: relative;
    display: block;
    vertical-align: top;
    width: 100px;
    height: 30px;
    padding: 3px;
    margin: 0 10px 10px 0;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
    border-radius: 18px;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
}
.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before, .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
}
.switch-label:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
    content: attr(data-on);
    left: 11px;
    color: #FFFFFF;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}
.switch-input:checked ~ .switch-label {
    background: #c5645b;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
    opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
    opacity: 1;
}
.switch-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
    left: 74px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-label, .switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}

.toogle-text-style{
   font-size: 18px;
   padding-top: 7px;
}
.cookie-toggle-position{
    margin-left: 65px;
}

.v-client-table-font-size{
    font-size-adjust: 0.48;
    font-size: 14px ;
}

//Cookie Policy page toggle end

.reg-circle-inherit{
    position: inherit;
}

.mr0 {
    margin-right: 0;
}

.pr0 {
    padding-right: 0;
}

.pl0 {
    padding-left: 0;
}

.card {
    height: 50px;
}

.charges-basic h2{
    color: #5A5A5A;
    font-weight: 900;
    padding: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 34px;
}

.charges-basic-text {
    margin-top: 24px;
    background: #FFFFFF;
}

.shared-item {
    min-height: 852px;
}

.sf-menu, .sf-menu *{
    z-index: 99;
}

@media only screen and (max-width: 675px) {
  .user-menu-item{
    width: 110px;
  }
}

@media only screen and (max-width: 545px) {
  .user-menu-item{
    white-space: nowrap;
    width: 50%;
    border-radius: 0;
    margin-bottom: 5px !important;
  }
}
.heading-content {
    font-size: 20px;


    .user-name {
        font-size: 20px;
        color: #b86662;
    }

    .edit-button{
        color: white;
        background-color: #b86662;
    }
    .hollow {
        background-color: transparent;
        border: 1px solid #2199e8;
        color: #2199e8;
    }
}
.profileInfo-item {
  display: flex;
  margin-top: 2px;
  padding: 5px 10px;
}

.profileInfo-item label{
  padding-left: 10px;
}

.profileInfo-showEdit {
  margin-top: 16px;
}

.verifyEmail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.vue-slider-tooltip {
    direction: ltr;
}
.cart-badge {
    border-radius: 5px;
    background-color: #fff;
    color: #c5645b;
    padding-right: 5px;
    padding-left: 4px;
    letter-spacing: 0;
    font-weight: bold;
}
#navCartBadge {
    margin-left: 5px;
}

.disabled{
    opacity: 0.4;
    pointer-events: none;
}

